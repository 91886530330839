const DotList = (props) => {
    return (
        <>
            <div className="h-100 border-end position-relative">
                <i className="bi-record-circle position-absolute" style={{fontSize: 25, right: -13, top: -4}} />
            </div>
        </>
    )
}

export default DotList;
