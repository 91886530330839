import axios from 'axios';
import {getToken, removeTokens} from "./auth";

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

api.interceptors.request.use(async (config) => {
    // config.headers['Access-Control-Allow-Origin'] = '*';
    const token = getToken();
    const urls = [
      "order/public-status/cancel/",
      "order/public-status/update-order-information/",
      "retailer/public-status/",
    ];
  
    if (token && urls.includes(config.url)) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  });

api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        removeTokens()
        // window.location.href = process.env.REACT_APP_APP_URL
    }
    console.log(error);
    return Promise.reject(error);
});
