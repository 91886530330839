import React, { useEffect, useState } from "react";
import Joi from "joi";
import { api } from "../../services/api";
import { useToasts } from "react-toast-notifications";
import "../../assets/modal/modal.css";
import { useNavigate } from 'react-router-dom'

const ChangeAddressModal = ({
  orderNumber,
  orderId,
  customer,
  errors,
  setCustomer,
  reload,
  setReload,
  setErrors,
  variantIds,
  channelId,
}) => {
  const { addToast } = useToasts();
  const address = customer.address1;
  const baseURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  // state list
  const stateList = {
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };
  const stateSelectOptions = Object.keys(stateList);
  const [defaultSelected, setDefaultSelected] = useState(false);
  const [fulfillmentStatus, setFulfillmentStatus] = useState(true);
  const [shippingState, setShippingState] = useState(null);
  const [data, setData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setCustomerData({ ...customerData, state: shippingState });
    checkForOrderFulfillmentStatus(shippingState);
  }, [shippingState]);

  useEffect(() => {
    setCustomerData(customer);
    document.getElementById("address1").value = customer.address1;
  }, [customer]);

  useEffect(() => {
    if(Object.keys(data).length > 0) {
      setCustomerData(data);
    }
  }, [data]);

  // validation schema
  let schema = {
    email: Joi.string().required().messages({
      "string.empty": `"Email" cannot be an empty field.`,
    }),
    firstName: Joi.string().required().messages({
      "string.empty": `"First name" cannot be an empty field.`,
    }),
    lastName: Joi.string().required().messages({
      "string.empty": `"Last name" cannot be an empty field.`,
    }),
    phoneNumber: Joi.string().min(8).max(20).required().messages({
      "string.empty": `"Phone number" cannot be an empty field.`,
      "string.min": "Phone number must be valid.",
      "string.max": "Phone number must be valid.",
    }),
    address1: Joi.string()
      .required()
      .messages({ "string.empty": `"Address 1" cannot be an empty field.` }),
    city: Joi.string().required().messages({
      "string.empty": `"City" cannot be an empty field.`,
    }),
    zipCode: Joi.string().min(5).max(5).required().messages({
      "string.empty": `"Zipcode" cannot be an empty field.`,
      "string.min": `"Zipcode" must be 5 digits.`,
      "string.max": `"Zipcode" must be 5 digits.`,
    }),
    state: Joi.string().required().messages({
      "string.empty": `Select value for "State".`,
    }),
  };

  /**
   * On change of the values of the field, check for validations.
   * If state is changed, variants availability is checked
   * @param {*} event
   */
  const handleChange = (event) => {
    const { name, value } = event.target;

    // check validation for other fields
    const keys = Object.keys(schema);
    if (keys.includes(name)) {
      let errorData = { ...errors };
      const errorMessage = validateProperty(name, value);
      if (errorMessage) {
        errorData[name] = errorMessage;
      } else {
        delete errorData[name];
      }
      setErrors(errorData);
    }

    setCustomer({ ...customer, [name]: value });
  };

  /**
   * Validate the field
   * @param {*} name - field name
   * @param {*} value - field value
   * @returns error|null
   */
  const validateProperty = (name, value) => {
    const obj = { [name]: value };
    const subSchema = Joi.object({ [name]: schema[name] });
    const result = subSchema.validate(obj);
    const { error } = result;
    return error ? error.details[0].message : null;
  };

  /**
   * Validate the form when save button is clicked
   * @param {event} event
   * @returns error|null
   */
  const validateForm = () => {
    schema = Joi.object(schema);
    const result = schema.validate({
      email: customerData.email,
      phoneNumber: customerData.phoneNumber,
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      address1: customerData.address1,
      city: customerData.city,
      zipCode: customerData.zipCode,
      state: shippingState,
    });
    const { error } = result;
    if (!error) {
      return null;
    } else {
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
      }
      setErrors(errorData);
      return errorData;
    }
  };

  /**
   * Close modal
   */
  const closeModal = () => {
    // Take close button DOM and call click event
    const closeButton = document.getElementById("address-modal-close-button");
    closeButton.click();
  }

  /**
   * Handle cancel of modal
   */
  const handleCancel = () => {
    document.getElementById("address1").value = address;
    setReload(!reload);
    setShippingState(null);
  }

  /**
   * On click of save button - update the order details
   * @param {event} event
   */
  const handleSave = async (event) => {
    setShowSpinner(true);
    event.preventDefault();
    if (Object.keys(errors).length > 0) {
      setShowSpinner(false);
      document.getElementById("save_change_btn").disabled = false;
      addToast("Please fix the errors!", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      const errorData = validateForm();
      if (!errorData) {
        // update the order data
        const requestBody = {
          email: customerData.email,
          shipping_name: customerData.firstName + " " + customerData.lastName,
          shipping_address1: customerData.address1,
          shipping_address2: customerData.address2,
          shipping_city: customerData.city,
          shipping_zip: customerData.zipCode,
          shipping_state: customerData.state,
          shipping_phone: customerData.phoneNumber,
          note: customerData.giftNote,
        };
        
        document.getElementById("save_change_btn").disabled = true;

        api
          .patch("order/public-status/update-order-information/", requestBody, {
            params: { id: orderId },
          })
          .then((response) => response)
          .then((response) => {
            if (response.status === 200) {
              addToast(response.data, {
                appearance: "success",
                autoDismiss: true,
              });
              // Close the modal
              closeModal();
              document.getElementById("save_change_btn").disabled = false;
              setShowSpinner(false);
              navigate(`/?order=${orderNumber}&phone=${customerData.phoneNumber}`, {
                state: {
                  order: orderNumber,
                  phone: customerData.phoneNumber
                }
              });
            } else {
              addToast(response.data, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          })
          .catch((err) => {
            setShowSpinner(false);
            document.getElementById("save_change_btn").disabled = false;
            addToast("Something went wrong, please try again!!", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      } else {
        setShowSpinner(false);
        document.getElementById("save_change_btn").disabled = false;
        addToast("Something went wrong, please try again!!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  /**
   * On change of auto-complete address field
   */
  function handleAutoCompleteAddressChange(event) {
    event.preventDefault();
    setCustomer({...customer, address1 : event.target.value });


    let addressElement = document.getElementById("address1");
    // address field validation
    const errorMessage = validateProperty("address1", addressElement.value);
    let errorData = { ...errors };
    if (errorMessage) {
      errorData["address1"] = errorMessage;
    } else {
      delete errorData["address1"];
    }
    setErrors(errorData);

    let places = new window.google.maps.places.Autocomplete(addressElement);

    // This event is triggered when any of the place is selected from the suggestions
    window.google.maps.event.addListener(places, "place_changed", function () {
      let place = places.getPlace();
      document.getElementById("address1").value = place.name;
      data["address1"] = place.name;

      // loop through the address values to auto populate city, state, zip values
      for (var i = 0; i < place.address_components?.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (addressType == "locality") {
          data["city"] = place.address_components[i].long_name;
        }
        if (addressType == "administrative_area_level_1") {
          let state = place.address_components[i].short_name;
          if (!stateSelectOptions.includes(state)) {
            state = "";
            setDefaultSelected(true);
          }
          setShippingState(state);
          data["state"] = state;
        }
        if (addressType == "postal_code") {
          data["zipCode"] = place.address_components[i].long_name;
        }
      }

      setData({...customerData, ...data});
      setCustomer({...customer, ...data});
    });
  }

 /**
  * Check the shipping state - if fulfillment options are available
  * @param {*} state - shipping state
  */
  const checkForOrderFulfillmentStatus = (state) => {
    if (!state) {
      let errorData = errors;
      if(errorData["state"]) {
        delete errorData["state"];
      }
      return setErrors(errors);
    }

    const url = new URL(baseURL + "retailer/public-status/");
    variantIds.map((id) => url.searchParams.append("variant_id", id));
    api
      .post(url.href, { shipping_state: state })
      .then((response) => {
        const {
          status,
          data,
          data: { result },
        } = response;
        if (status === 200 && data) {
          let flag = false;

          if (!result || result.length === 0) {
            // if the result contains false or empty[]
            flag = true;
          } else {
            for (let item of result) {
              for (let id of variantIds) {
                if (item[id] && !item[id][0].type) {
                  flag = true;
                  break;
                }
              }
            }
          }

          if (flag) {
            setErrors({
              ...errors,
              state: "No fulfillment options available to this state",
            });
            setFulfillmentStatus(false);
          } else {
            let errorData = errors;
            delete errorData["state"];
            setErrors({ ...errors, ...errorData });
            setFulfillmentStatus(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id="bc-modal-change-address"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="bc-modal-change-address-label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          {/* Model Content*/}
          <div className="modal-content">
            {/* Model header*/}
            <div className="modal-header bg-dark text-light">
              <h5 className="modal-title" id="bc-modal-change-address-label">
                Change Address - {orderNumber}
              </h5>
              <button
                type="button"
                id="address-modal-close-button"
                className="btn-close bg-light"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
              />
            </div>
            {/* Model Body*/}
            <div className="modal-body">
              <form>
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="email">Email address *</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={customerData.email ?? ""}
                          placeholder="Email address"
                          disabled={channelId !== 132}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      {errors.email && (
                        <div className="m-0 p-1 h-35 alert alert-danger">
                          {errors.email}
                        </div>
                      )}
                    </div>

                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="phone-number">Phone number *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone-number"
                          name="phoneNumber"
                          value={customerData.phoneNumber ?? ""}
                          placeholder="Phone number"
                          onChange={handleChange}
                          disabled={channelId === 132}
                          required
                        />
                      </div>
                      {errors.phoneNumber && (
                        <div className="m-0 p-1 h-35 alert alert-danger">
                          {errors.phoneNumber}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="first-name">Fist name *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="first-name"
                          name="firstName"
                          value={customerData.firstName ?? ""}
                          placeholder="First name"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      {errors.firstName && (
                        <div className="m-0 p-1 h-35 alert alert-danger">
                          {errors.firstName}
                        </div>
                      )}
                    </div>
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="last-name">Last name *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="last-name"
                          name="lastName"
                          value={customerData.lastName ?? ""}
                          placeholder="Last name"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      {errors.lastName && (
                        <div className="m-0 p-1 h-35 alert alert-danger">
                          {errors.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="address1">Address 1 *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="address1"
                          name="address1"
                          value={customerData.address1 ?? ""}
                          placeholder="Address 1"
                          onChange={handleAutoCompleteAddressChange}
                          autocomplete="address-line1"
                          required
                        />
                      </div>
                      {errors.address1 && (
                        <div className="m-0 p-1 h-35 alert alert-danger">
                          {errors.address1}
                        </div>
                      )}
                    </div>
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="address2">Address 2</label>
                        <input
                          type="text"
                          className="form-control"
                          id="address2"
                          name="address2"
                          value={customerData.address2 ?? ""}
                          placeholder="Address 2"
                          autocomplete="address-line2"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="city">City *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                          value={customerData.city ?? ""}
                          placeholder="City"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      {errors.city && (
                        <div className="m-0 p-1 h-35 alert alert-danger">
                          {errors.city}
                        </div>
                      )}
                    </div>
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="state">State *</label>
                        <select
                          className="form-select"
                          name="state"
                          value={shippingState ?? customerData.state}
                          onChange={(e) => setShippingState(e.target.value)}
                        >
                          <option defaultChecked={defaultSelected} disabled>
                            {" "}
                          </option>
                          {stateSelectOptions.map((item, key) => {
                            return (
                              <option value={item} id={"ID-" + key} key={key}>
                                {stateList[item]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errors.state && (
                        <div className="m-0 p-1 h-35 alert alert-danger">
                          {errors.state}
                        </div>
                      )}
                    </div>
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="zipcode">Zipcode *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="zipcode"
                          name="zipCode"
                          value={customerData.zipCode ?? ""}
                          placeholder="Zipcode"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      {errors.zipCode && (
                        <div className="m-0 p-1 h-35 alert alert-danger">
                          {errors.zipCode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm">
                      <div className="form-group">
                        <label htmlFor="gift-note">Gift note</label>
                        <input
                          type="text"
                          className="form-control"
                          id="gift-note"
                          name="giftNote"
                          value={customerData.giftNote ?? ""}
                          placeholder="Gift note"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* Model Footer*/}
            <div className="modal-footer justify-content-start justify-content-md-end ">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleCancel}
              >
                Cancel
              </button>
              {errors.state && !fulfillmentStatus ? (
                <a
                  className="btn btn-dark"
                  href={"mailto:openbar@mashandgrape.com"}
                >
                  Contact Support
                </a>
              ) : (
                <button
                  id="save_change_btn"
                  type="button"
                  className="btn btn-dark"
                  onClick={handleSave}
                >
                  {
                    showSpinner && (
                      <span className="spinner-border spinner-border-sm" style={{marginLeft:0, marginRight:6}}></span>
                    )
                  }
                  Save changes
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeAddressModal;
