import SimpleFooter from '../Theme/SimpleFooter'
import React from 'react'
import Hero from '../Theme/IndexSections/Hero'
import DemoNavbar from '../Theme/DemoNavbar'
import StatusPage from './Status'


class StatusBase extends React.Component {
  componentDidMount () {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render () {

    return (
      <>
        <DemoNavbar/>
        <main ref="main">
          <Hero/>
          <section className="section">
            <StatusPage/>
          </section>
        </main>
        <SimpleFooter/>
      </>
    )
  }
}

export default StatusBase
