const TTopicHeader = (props) => {
    return (
        <>
            <div className="text-black-50 ps-2 p-3 pb-1">
                <p style={{fontSize: 18}}>{props.value}</p>
            </div>
        </>
    )
}

export default TTopicHeader;
