const TTime = (props) => {
    return (
        <>
            <div className="text-end text-black-50 small">
                {props.value}
            </div>
        </>
    )
}

export default TTime;
