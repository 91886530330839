const DotTopicHeader = (props) => {
    return (
        <>
            <div className="h-100 border-end position-relative">
                <i className="bi-circle-fill position-absolute pt-3 pb-1" style={{color: "#ddd", right: -8, top: 2}} />
            </div>
        </>
    )
}

export default DotTopicHeader;
