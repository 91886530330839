import React from 'react';

const ContactSupportButton = ({ sendEmailTo = 'support@getbarcart.com' }) => {
  return (
    <a
      className="btn btn-dark col-12 col-md-3 col-lg-2 mb-1"
      href={`mailto:${sendEmailTo}`}
      data-bs-toggle="tooltip"
      title={
        'Please email us at support@getbarcart.com or fill out a contact form on the bottom right hand corner of the screen. A member of our team will get back to you within 24 hours. Thanks!'
      }
    >
      Contact Support
    </a>
  );
};

export default ContactSupportButton;
