import React, { useEffect, useState } from 'react'
import { api } from '../../services/api'
import DotList from '../Timeline/DotList'
import TTime from '../Timeline/TTime'
import TContent from '../Timeline/TContent'
import TTopicHeader from '../Timeline/TTopicHeader'
import DotTopicHeader from '../Timeline/DotTopicHeader'
import Moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import FullLoading from '../Utils/FullLoading'
import { Toast, Tooltip } from 'bootstrap'
import { useToasts } from 'react-toast-notifications'
import { UseAuth } from '../Auth/Auth'
import { removeTokens, setToken } from '../../services/auth'
import ButtonLoader from '../Utils/ButtonLoader'
import ModalConfirmation from '../Utils/ModalConfirmation'
import PendingStatuses from '../../constants/PendingStatus.constant'
import ChangeAddressModal from '../Modal/ChangeAddressModal'
import DemoNavbar from '../Theme/DemoNavbar'
import Hero from '../Theme/IndexSections/Hero'
import SimpleFooter from '../Theme/SimpleFooter'

const StatusPage = (props) => {

  const [order, setOrder] = useState(null)
  const [status, setStatus] = useState(null)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [customer, setCustomer] = useState({})
  const [reload, setReload] = useState(false)
  const [errors, setErrors] = useState({})
  const [variantIds, setVariantIds] = useState([])

  const { addToast } = useToasts()
  const auth = UseAuth()
  const orderStatus = [
    'OPEN',
    'ACTION_REQUIRED',
    'ORDERED_PRODUCT',
    'PRE_ORDER',
    'INCORRECT_ADDRESS',
    'ON_HOLD',
    'PRE_CONFIRMED',
    'NEED_DISTRIBUTOR_INFO',
    'DRAFT',
  ]

  let currentChangeDate = null
  let currentTrackingDate = null
  let lastTrackingMessage = null

  function useQuery () {
    return new URLSearchParams(useLocation().search + window.location.hash)
  }

  const navigate = useNavigate()

  let orderNumber = encodeURI(useQuery().get('order'))
  let orderEmail = useQuery().get('email')
  let orderPhone = useQuery().get('phone')

  useEffect(() => {
    api.get('/order/public-status/', {
      params: {
        email: orderEmail ? orderEmail : null,
        phone: orderPhone ? orderPhone : null,
        order: orderNumber ? orderNumber : null,
      },
    }).then((response) => {
      if (response.data.errors !== undefined) {
        navigate('/login', {
          state: { message: response.data?.errors?.order },
        })
      } else {
        if (response.status === 200) {
          setOrder(response.data)

          const {
            email,
            shipping_phone,
            shipping_name,
            shipping_address1,
            shipping_address2,
            shipping_city,
            shipping_state,
            shipping_zip,
            note,
            line_items,
            phone,
          } = response.data
          const name = shipping_name?.split(' ')

          setCustomer({
            email: email,
            phoneNumber: shipping_phone,
            firstName: name !== undefined ? name[0] : '',
            lastName: name !== undefined ? name[1] : '',
            address1: shipping_address1,
            address2: shipping_address2,
            city: shipping_city,
            state: shipping_state,
            zipCode: shipping_zip,
            giftNote: note,
          })

          const variantIdList = line_items.map(
            (item) => item.variant.shopify_id,
          )
          setVariantIds(variantIdList)

          let authParams = {
            order_id: orderNumber,
          }

          if (orderEmail) {
            authParams = { ...authParams, email: email }
          } else if (orderPhone) {
            authParams = { ...authParams, phone: phone }
          }

          api.post('order/public-status/auth/', authParams).then((response) => {
            const { token } = response.data
            removeTokens()
            setToken(token)
          }).catch((err) => {
            console.log(err)
            navigate('/login', {
              state: { message: err?.message || err },
            })
          })
        }
      }
    }).catch((error) => console.log(error))

    setErrors({})
  }, [orderNumber, orderEmail, navigate, status, reload])

  useEffect(() => {
    document.querySelectorAll('[data-bs-toggle="tooltip"]')?.
      forEach(function (item, i) {
        new Tooltip(item)
      })
  })

  useEffect(() => {
    if (order && PendingStatuses.includes(order.order_status)) {
      setStatus('PENDING CONFIRMATION')
    } else {
      setStatus(String(order?.order_status).replace('_', ' '))
    }
  }, [order?.order_status])

  function handleCancellation () {
    setIsButtonLoading(true)
    api.put('order/public-status/cancel/', {
      'order': order?.id,
      'amount': parseInt(order?.total * 100),
    }).then(response => {
      if (response.data.success) {
        setStatus(null)
        setIsButtonLoading(false)
        addToast('Order cancelled successfully!',
          { appearance: 'success', autoDismiss: true })
      } else {
        setIsButtonLoading(false)
        addToast('Order could not to be cancelled',
          { appearance: 'error', autoDismiss: false })
      }
    }).catch(err => {console.log(err)})
  }

  useEffect(() => {
    if (order?.store_id === 1 && order?.channel_id !== 40) {
      // eslint-disable-next-line no-unused-expressions
      !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
      window.Beacon('init', '4f9c09da-d0f6-484f-afa5-5f7dca62487a');
    } else {
      // eslint-disable-next-line no-unused-expressions
      !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
      window.Beacon('init', 'df8f06ef-def1-4fa6-be73-cb482eb78839');
    }
  }, [order])

  return (
    <div className={"position-relative"}>
      {order?.id ?
        <>
          <div className="navbar">
            <div className="container-fluid">
              <div className="navbar-brand mx-auto">
                <p className="display-1 fw-bold">Order {order?.shopify_id
                  ? order?.shopify_id
                  : order?.name}</p>
              </div>
            </div>
          </div>
          <div
            className="container-fluid container-lg py-md-5 my-md-5 px-lg-5">
            <div className="container-fluid container-lg px-lg-5 mt-lg-5">
              <div className="row row-cols-1">
                <div className="col col-md-6">
                  <div className="card border h-100">
                    <div className="card-body small p-4">
                      <h4 className="text-center">Order Status</h4>
                      <div className="text-center mt-4">
                        <h3 className="">
                          {status !== 'CLOSED'
                            ? (status !== undefined) ? status :
                              <FullLoading/>
                            : order?.fulfillments.length
                              ? order?.fulfillments[0].shipment_status.toUpperCase().
                                replace('_', ' ')
                              : '-'
                          }
                        </h3>
                        {order?.inventory_location?.name &&
                          <p>Fulfilled
                            By {order?.inventory_location?.full_name}</p>
                        }
                        {order?.fulfillments.length ?
                          <>
                            <div className="ps-5">
                              <a
                                href={order?.fulfillments[0].tracking_url}
                                className="btn btn-link text-decoration-none select-text"
                              >
                                {order?.fulfillments[0].tracking_number}
                              </a>
                              {/* Button to copy tracking number to clipboard */}
                              <button
                                type="button"
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => {
                                  // Copy tracking number to clipboard
                                  navigator.clipboard.writeText(
                                    order?.fulfillments[0].tracking_number)

                                  // Show toast that tracking number copied
                                  const toastLiveExample = document.getElementById(
                                    'clipboard-toast')
                                  const toast = new Toast(toastLiveExample)
                                  toast.show()
                                }}
                                title="Click to copy tracking number"
                              >
                                <i className={'bi bi-clipboard'}/>
                              </button>
                            </div>
                            <div>
                              <span
                                className="text-uppercase">
                                  {order?.fulfillments[0].tracking_company}
                              </span>
                            </div>
                            {
                              order?.fulfillments[0].expected_delivery_date !=
                              null &&
                              <div className="mt-4">
                                <h6 className="fw-bold">Expected Delivery
                                  Date</h6>
                                <span className="text-uppercase">
                                                                {Moment(
                                                                  order?.fulfillments[0].expected_delivery_date).
                                                                  format(
                                                                    'MMMM D, YYYY')}
                                                            </span>
                              </div>
                            }
                          </>
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col col-md-6">
                  <div className="card border h-100 mt-5 mt-sm-0">
                    <div className="card-body small p-sm-4">
                      <h4 className="text-center">Customer Information</h4>
                      <div className="text-center mt-4">
                        <h5
                          className="">{order?.customer?.first_name} {order?.customer?.last_name}</h5>
                      </div>
                      <div className="row pt-3">
                        <div className="col-6">
                          <h6 className="fw-bold">Delivery Address</h6>
                          <div className="mt-3">
                            <div>
                              <b>{order?.shipping_name}</b>
                              <div className="py-1 pt-2">
                                {order?.shipping_address1} {',' +
                              order?.shipping_address2
                                ? order?.shipping_address2
                                : ''}
                              </div>
                              <div
                                className="py-1">{order?.shipping_city}{order?.shipping_city
                                ? ', '
                                : ''}{order?.shipping_state}{order?.shipping_state
                                ? ', '
                                : ''}{order?.shipping_zip}</div>
                              <div
                                className="py-1">{order?.shipping_country}</div>
                              <div
                                className="py-1">{order?.shipping_phone}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <h6 className="fw-bold">Billing Address</h6>
                          <div className="mt-3">
                            <div>
                              <b>{order?.billing_name}</b>
                              <div className="py-1 pt-2">
                                {order?.billing_address1} {',' +
                              order?.billing_address2
                                ? order?.billing_address2
                                : ''}
                              </div>
                              <div
                                className="py-1">{order?.billing_city}{order?.billing_city
                                ? ', '
                                : ''}{order?.billing_state}{order?.billing_state
                                ? ', '
                                : ''}{order?.billing_zip}</div>
                              <div
                                className="py-1">{order?.billing_country}</div>
                              <div
                                className="py-1">{order?.billing_phone}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="card border-0 py-3">
                    <div
                      className="card-body d-grid d-md-flex justify-content-md-around">
                      {order?.order_status === 'OPEN' ||
                      order?.order_status === 'ACTION_REQUIRED'
                        ? order?.channel_id === 40 ?
                          <button
                            className="btn btn-dark col-12 col-md-3 mb-1"
                            data-bs-toggle="modal"
                            data-bs-target="#bc-modal-confirmation">
                            {isButtonLoading ? <ButtonLoader/> : 'Cancel'}
                          </button>
                          : order?.store_id === 1 && order?.channel_slug !==
                          'online-store' ?
                            <a className="btn btn-dark col-12 col-md-3 mb-1"
                               href={'mailto:openbar@mashandgrape.com'}>
                              Cancel
                            </a>
                            : <a
                              className="btn btn-dark col-12 col-md-3 col-lg-2 mb-1"
                              href={'mailto:support@getbarcart.com'}>
                              Cancel
                            </a>
                        :
                        <div className="col-12 col-md-3 mb-1"
                             data-bs-toggle="tooltip"
                             title="Can't cancel order at this stage">
                          <button
                            className="btn btn-dark disabled col-12">Cancel
                          </button>
                        </div>
                      }
                      {order?.channel_id === 40 || order?.channel_id === 132
                        ? (
                          orderStatus.includes(order?.order_status) && (
                            <button
                              className="btn btn-dark col-12 col-md-3 mb-1"
                              data-bs-toggle="modal"
                              data-bs-target="#bc-modal-change-address"
                            >
                              {isButtonLoading ? (
                                <ButtonLoader/>
                              ) : (
                                'Update Address'
                              )}
                            </button>
                          )
                        )
                        : order?.order_status !== 'OPEN' &&
                        order?.order_status !== 'DRAFT' ? (
                          <div
                            className="col-12 col-md-3 mb-1"
                            data-bs-toggle="tooltip"
                            title="Can't change address at this stage"
                          >
                            <button
                              className="btn btn-dark col-12 disabled">
                              Update Address
                            </button>
                          </div>
                        ) : order?.channel_slug === 'online-store' ? (
                          <a
                            className="btn btn-dark col-12 col-md-3  mb-1"
                            href={
                              'mailto:support@getbarcart.com'
                            }
                          >
                            Update Address
                          </a>
                        ) : (
                          <a
                            className="btn btn-dark col-12 col-md-3 mb-1"
                            href={
                              'mailto:openbar@mashandgrape.com'
                            }
                          >
                            Update Address
                          </a>
                        )
                      }
                      {
                        (order?.store_id === 1 && order?.channel_id !==
                          40 && order?.channel_id !== 132)
                          ? <a className="btn btn-dark col-12 col-md-3 mb-1"
                               href="mailto:openbar@mashandgrape.com"
                               data-bs-toggle="tooltip"
                               title={'Please email us at openbar@mashandgrape.com or fill out a contact form on the bottom right hand corner of the screen. A member of our team will get back to you within 24 hours. Thanks!'}>
                            Contact Support
                          </a>
                          : <a className="btn btn-dark col-12 col-md-3 mb-1"
                               href="mailto:support@getbarcart.com"
                               data-bs-toggle="tooltip"
                               title={'Please email us at support@getbarcart.com or fill out a contact form on the bottom right hand corner of the screen. A member of our team will get back to you within 24 hours. Thanks!'}>
                            Contact Support
                          </a>
                      }
                    </div>
                  </div>
                </div>

                <div className="col col-md-6">
                  <div className="card border mt-4 h-100">
                    <div className="card-body">
                      <h4 className="text-center">Timeline</h4>

                      {order?.fulfillments.length
                        ? order?.fulfillments[0].tracking_history.map(
                          (item, i) => {
                            if (item.message !== lastTrackingMessage) {
                              lastTrackingMessage = item.message
                              if (Moment(item.created_at).
                                  format('MMMM D, YYYY') !==
                                currentTrackingDate) {
                                currentTrackingDate = Moment(
                                  item.created_at).format('MMMM D, YYYY')
                                return (
                                  <div key={item.id}>
                                    <div className="row"
                                         style={{ minHeight: 50 }}>
                                      <div className="col-1">
                                        <DotTopicHeader/>
                                      </div>
                                      <div className="col-9">
                                        <TTopicHeader
                                          value={new Moment(
                                            item.created_at).format(
                                            'MMMM D, YYYY')}/>
                                      </div>
                                      <div className="col-2 text-right"/>
                                    </div>
                                    <div className="row position-relative"
                                         style={{ minHeight: 50 }}>
                                      <div className="col-1"><DotList/>
                                      </div>
                                      <div className="col-9">
                                        <TContent value={item.message}/>
                                        <div
                                          className="text-right d-sm-none position-absolute top-0 right-0"
                                          style={{ fontSize: 11 }}>
                                          <TTime
                                            value={new Moment(
                                              item.created_at).format(
                                              'hh:mma')}/>
                                        </div>
                                      </div>
                                      <div
                                        className="col col-sm-2 d-none d-sm-block">
                                        <TTime
                                          value={new Moment(
                                            item.created_at).format(
                                            'hh:mma')}/>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }

                              return (
                                <div className="row position-relative"
                                     style={{ minHeight: 50 }}
                                     key={item.id}>
                                  <div className="col-1"><DotList/></div>
                                  <div className="col-9">
                                    <TContent value={item.message}/>
                                    <div
                                      className="text-right d-sm-none position-absolute top-0 right-0"
                                      style={{ fontSize: 11 }}>
                                      <TTime
                                        value={new Moment(
                                          item.created_at).format(
                                          'hh:mma')}/>
                                    </div>
                                  </div>
                                  <div
                                    className="col col-sm-2 d-none d-sm-block">
                                    <TTime
                                      value={new Moment(
                                        item.created_at).format('hh:mma')}/>
                                  </div>
                                </div>
                              )
                            }
                            return ''
                          })
                        : ''}

                      {order?.order_changes.map((item, i) => {
                        if (Moment(item.created_at).
                          format('MMMM D, YYYY') !== currentChangeDate) {
                          currentChangeDate = Moment(item.created_at).
                            format('MMMM D, YYYY')
                          return (
                            <div key={item.id}>
                              <div className="row"
                                   style={{ minHeight: 50 }}>
                                <div className="col-1">
                                  <DotTopicHeader/>
                                </div>
                                <div className="col-9">
                                  <TTopicHeader
                                    value={new Moment(
                                      item.created_at).format(
                                      'MMMM D, YYYY')}/>
                                </div>
                                <div className="col-2 text-right"/>
                              </div>
                              <div className="row position-relative"
                                   style={{ minHeight: 50 }}>
                                <div className="col-1"><DotList/></div>
                                <div className="col-9">
                                  <TContent value={item.description}/>
                                  <div
                                    className="text-right d-sm-none position-absolute top-0 right-0"
                                    style={{ fontSize: 11 }}>
                                    <TTime
                                      value={new Moment(
                                        item.created_at).format('hh:mma')}/>
                                  </div>
                                </div>
                                <div
                                  className="col col-sm-2 d-none d-sm-block">
                                  <TTime
                                    value={new Moment(
                                      item.created_at).format('hh:mma')}/>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        return (
                          <div className="row position-relative"
                               style={{ minHeight: 50 }} key={item.id}>
                            <div className="col-1"><DotList/></div>
                            <div className="col-9">
                              <TContent value={item.description}/>
                              <div
                                className="text-right d-sm-none position-absolute top-0 right-0"
                                style={{ fontSize: 11 }}>
                                <TTime
                                  value={new Moment(item.created_at).format(
                                    'hh:mma')}/>
                              </div>
                            </div>
                            <div className="col col-sm-2 d-none d-sm-block">
                              <TTime
                                value={new Moment(item.created_at).format(
                                  'hh:mma')}/>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className="col col-md-6 mt-4">
                  <div className="card border mt-5 mt-sm-0 h-100">
                    <div className="card-body small p-lg-4">
                      <h4 className="text-center">Order Details</h4>
                      <div className="py-2"/>
                      <div className="mt-5">
                        {order?.line_items.map((item, i) => (
                            <div className="row mb-4" key={item.id}>
                              <div
                                className="col-4 col-md-3 col-lg-3 text-left">
                                {item.product?.product_images.length > 0 &&
                                  <img
                                    src={item.product?.product_images[0].file}
                                    alt={''}
                                    width="100%"/>
                                }
                              </div>
                              <div className="col-6 col-lg-6">
                                <div><b>{item.product?.name}</b></div>
                                    <div
                                      className="mt-1">{item.variant?.size?.text}</div>
                                  </div>
                                  <div
                                    className="col-2 col-md-3 col-lg-1  text-right">x {item.quantity
                                    ? item.quantity
                                    : 0}
                                  </div>
                                  <div
                                    className="col-12 col-md-12 col-lg-2 text-right">$ {item.price
                                    ? parseFloat(item.price).toFixed(2)
                                    : 0.00}</div>
                                </div>
                              ),
                            )}
                          </div>

                          <hr/>

                          <div className="mt-4">
                            <div className="row mb-2">
                              <div className="col-6">Subtotal</div>
                              <div
                                className="col-6 text-right">$ {order?.subtotal
                                ? parseFloat(order?.subtotal).toFixed(2)
                                : 0.00}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-6">Shipping</div>
                              <div
                                className="col-6 text-right">$ {order?.shipping
                                ? parseFloat(order?.shipping).toFixed(2)
                                : 0.00}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-6">Taxes</div>
                              <div
                                className="col-6 text-right">$ {order?.taxes
                                ? parseFloat(order?.taxes).toFixed(2)
                                : 0.00}</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-6">
                                {'Discount '}
                                {order?.discount_code &&
                                  <span className="badge bg-secondary small"
                                        style={{ fontWeight: 'normal' }}>{order?.discount_code}</span>}
                              </div>
                              <div
                                className="col-6 text-right">-
                                $ {order?.discount_amount
                                  ? parseFloat(order?.discount_amount).
                                    toFixed(2)
                                  : 0.00}</div>
                            </div>
                          </div>
                          <hr className="my-2"/>
                          <div className="row mb-2 fw-bold">
                            <div className="col-6">Total</div>
                            <div
                              className="col-6 text-right">$ {order?.total
                              ? parseFloat(order?.total).toFixed(2)
                              : 0.00}</div>
                          </div>

                          <div className="py-2"/>

                          <div className="mt-1">
                            <div className="row mb-3">
                              <div className="col-3 fw-bold">Notes</div>
                              <div
                                className="col-9 text-right">{order?.note
                                ? order?.note
                                : '-'}</div>
                            </div>
                            <div className="row mb-2">
                              <div
                                className="col-12 col-lg-6 fw-bold">Instructions
                                to Seller
                              </div>
                              <div
                                className="col-12 col-lg-6 text-lg-right">{order?.notes_to_sellers.length
                                ? order?.notes_to_sellers[0]?.value
                                : '-'}</div>
                            </div>
                          </div>

                          <div className="mt-3">
                            <dl className="row mb-0">
                              <dt className="col-12 col-lg-4">Delivery Method
                              </dt>
                              <dd
                                className="col-12 col-lg-8 text-lg-right">{order?.shipping_method}</dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col my-5 my-sm-4">
                      <div className="card border mt-4 h-100">
                        <div className="card-body small">
                          <h4 className="text-center">FAQ's</h4>
                          <div className={'pt-4'}>
                            <b>Q: Will I receive a tracking number?</b>
                            <p>A: Absolutely! Once your order is fulfilled, you
                              will receive a notification email including a
                              tracking link.</p>

                            <b>Q: My order was damaged.</b>
                            <p>A: We’re sorry about that! As such, per our
                              current policy, we will issue you a refund for
                              your order in full. If you would still like the
                              product/s, you may place a new order on the site!
                              Please email us for further assistance.</p>

                            <b>Q: When will my order ship?</b>
                            <p>A: Most orders, unless it’s a pre-order, are
                              processed within 1 to 5 business days and are
                              fulfilled by our licensed retail partners.</p>

                            <span className={'small text-muted'}>**Please note, there may be delays due to holidays and inclement weather.**</span><br/>
                            <span className={'small text-muted'}>We look forward to you receiving your order!</span>
                            <div className={'text-center mt-4'}>
                              <a href={`
                                                    ${(order.store_id == 1 &&
                                order.channel_id != 40)
                                ? 'https://help.mashandgrape.com/'
                                : 'https://help.getbarcart.com/collection/972-barcart-for-consumers'} 
                                                        `}
                                 className={'text-dark text-decoration-none'}>
                                <i className={'bi bi-question-circle'}/>&nbsp;
                                Click here for more questions
                              </a>
                            </div>
                          </div>
                          {/*<div className="py-5"/>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ModalConfirmation callback={handleCancellation}
                                 title={'Cancel Confirmation'}
                                 message={'Are you sure you’d like to cancel order ' +
                                   order?.get_order_number}/>
              <ChangeAddressModal
                orderNumber={orderNumber}
                orderId={order.id}
                customer={customer}
                setCustomer={setCustomer}
                reload={reload}
                setReload={setReload}
                errors={errors}
                setErrors={setErrors}
                variantIds={variantIds}
                channelId={order?.channel_id}
              />
              <div
                className="toast-container position-fixed top-50 left-50 translate-middle p-3">
                <div id="clipboard-toast" className="toast" role="alert"
                     aria-live="assertive" aria-atomic="true">
                  <div className="toast-body">
                    Tracking number copied to clipboard.
                  </div>
                </div>
              </div>
        </>
        : <FullLoading/>}
    </div>
  )
}

export default StatusPage
