import {useState} from "react";
import {omit} from 'lodash'

const useForm = (callback) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    const validate = (e, name, value) => {
        switch (name) {
            case 'email':
                if (!value) {
                    setErrors({
                        ...errors,
                        email: 'Email address is required'
                    })
                } else if (!new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) {
                    setErrors({
                        ...errors,
                        email: 'Enter a valid email address'
                    })
                } else {
                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                }
                break;
            default:
                break;
        }
    }

    const handleChange = (e) => {
        e.persist();
        let name = e.target.name;
        let value = e.target.value;

        validate(e, name, value);

        setValues({...values, [name]: value});
    }

    const handleSubmit = (e) => {
        if (e) e.preventDefault();

        if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
            callback();
        } else {
            console.log("There is an error");
        }
    }

    return {
        values,
        errors,
        handleChange,
        handleSubmit
    };
}
export default useForm;
