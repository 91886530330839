import {useState} from "react";
import {api} from "../../services/api";
import PendingStatuses from "../../constants/PendingStatus.constant";

const TContent = (props) => {
    const [description, setDescription] = useState("")

    function handleDescription(value) {
        if (String(value).includes("Changed order assignee to:")) {
            let assigneeName = String(value).replace("Changed order assignee to: ", "")
            api.get(
                'inventory-location/find/by-name/', {
                    params: {name: assigneeName}
                }
            ).then(response => {
                if (response.data.result?.full_name !== null) {
                    setDescription(String(value).replace(assigneeName, response.data.result?.full_name))
                } else {
                    setDescription(String(value))
                }
            }).catch(error => console.log(error))
            return description
        } else {
            // Replace occurrences of all the pending status from current status string
            let status = value;
            PendingStatuses.forEach(pendingStatus => {
                status = String(status).replace(pendingStatus.split("_").join(" "), "PENDING CONFIRMATION");
            });

            return status;
        }
    }

    return (
        <>
            <div className="ps-2 pb-1">{handleDescription(props.value)}</div>
        </>
    )
}

export default TContent;
