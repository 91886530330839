import React from 'react';

const Card = ({ children }) => {
  return (
    <>
      <div className="card border h-100 mb-4">
        <div className="card-body small p-3">{children}</div>
      </div>
    </>
  );
};

export default Card;
