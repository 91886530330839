import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {UseAuth} from "./Auth";
import useForm from "../Hooks/UseForm";
import Message from "../Utils/Message";
import {api} from "../../services/api";
import {removeTokens, setToken} from "../../services/auth";
import { sha256 } from 'js-sha256';

const Login = () => {
    const {handleChange, errors, handleSubmit} = useForm(handleLogin);

    const navigate = useNavigate();
    const {state} = useLocation();
    const {message} = state;

    const [email, setEmail] = useState(null);
    const [order, setOrder] = useState(null);

    const auth = UseAuth();

    const convertSHA256 = (inputText) => {
        // To hash a passed string using SHA-256 algorithm, BCD- 2699 hash email
        return sha256(inputText);
    };

    function handleLogin(e) {
        if (e) e.preventDefault();
        if (email && order) {
            auth.login(true, () => {
                api.post("order/public-status/auth/", {
                    "order_id": order,
                    "email": email
                }).then(response => {
                    const {token} = response.data
                    removeTokens()
                    setToken(token)
                }).catch(err => {console.log(err)})

                navigate(`/?order=${order}&email=${convertSHA256(email)}`, {
                    state: {
                        order: order,
                        email: email
                    }
                });
            })
        }
    }

    function handleInputChange(e) {
        handleChange(e);
        if (e.target.name === "email") {
            setEmail(e.target.value);
        } else if (e.target.name === "order") {
            setOrder(e.target.value);
        }
    }

    return (
        <div className="container container-md">
            <h3 className="text-center mb-5">{"Look it up with your order number."}</h3>
            <h5 className="text-center mb-5">{"Find an individual order."}</h5>
            <div className="row">
                <div className="col-11 col-md-6 col-lg-3 mx-auto">
                    <Message message={message}/>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input type="email" className="form-control form-control-lg"
                                   id="email" name="email" required={true}
                                   onChange={handleInputChange} placeholder={"Email"}/>
                            {errors.email && <div className="small text-danger">{errors.email}</div>}
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control form-control-lg"
                                   id="order" name="order" required={true}
                                   onChange={handleInputChange} placeholder={"Order"}/>
                            {errors.order && <div className="small text-danger">{errors.order}</div>}
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn-dark w-100 btn-lg">Continue</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
