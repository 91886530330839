const Message = (props) => {
    return (
        <>
            {props.message &&
            <div className="alert alert-danger">
                <>{props.message}</>
            </div>
            }
        </>
    )
}
export default Message;
