import React from 'react'
import { useToasts } from 'react-toast-notifications';

const InvalidDetails = () => {
    const { addToast } = useToasts();

    React.useEffect(() => {
        setTimeout(() => window.location.href = "/", 4000);
        addToast("You have entered incorrect details ! Please check")
    }, [])

    return (
        <></>
    )
}

export default InvalidDetails