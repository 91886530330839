import { Route, Routes } from 'react-router-dom'
import Login from './components/Auth/Login'
import RequireAuth from './components/Auth/RequireAuth'
import { AuthProvider } from './components/Auth/Auth'
import OrderStatusPage from './components/Pages/Order'
import { ToastProvider } from 'react-toast-notifications'
import Redirect from './components/Pages/Redirect'
import BulkOrderStatusPage from './components/Pages/bulk-order-status'
import { useEffect, useState } from 'react'
import StatusBase from './components/Pages/StatusBase'

const ZENDESK_KEY = '7409d7cf-84e9-4065-8b16-6fac747c6533'

function App () {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (window.location.pathname === '/status/') {
      import('./assets/vendor/nucleo/css/nucleo.css')
      import('./assets/vendor/font-awesome/css/font-awesome.min.css')
      import('./assets/css/argon-design-system-react.css')
    } else {
      import('./assets/scss/main.scss')
    }
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])

  return (
    !!loaded && (
      <ToastProvider>
        <AuthProvider>
          <Routes>
            <Route path="/" element={
              <RequireAuth>
                <OrderStatusPage/>
              </RequireAuth>
            }/>
            <Route path="/status" element={
              <RequireAuth>
                <StatusBase/>
              </RequireAuth>
            }/>
            <Route path="/bulk-order" element={<BulkOrderStatusPage/>}/>
            <Route path="/r/:id" element={<Redirect/>}/>
            <Route path="/login" element={<Login/>}/>
          </Routes>
        </AuthProvider>
        {/*<Zendesk defer zendeskKey={ZENDESK_KEY}/>;*/}
      </ToastProvider>
    )
  )
}

export default App
