const ModalConfirmation = ({callback, title, message}) => {

    const handleClick = () => {
        callback();
    }

    return (
        <>
            <div className="modal fade" id="bc-modal-confirmation" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="bc-modal-confirmation-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-dark text-light">
                            <h5 className="modal-title" id="bc-modal-confirmation-label">{title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body text-center">{message}</div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal" onClick={handleClick}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModalConfirmation;
