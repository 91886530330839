import {createContext, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {removeTokens} from "../../services/auth";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const  navigate = useNavigate();

    const login = (auth, callback) => {
        setIsAuthenticated(true);
        callback();
    }

    const logout = (callback) => {
        setIsAuthenticated(false);
        removeTokens();
        callback();
    }

    const auth = {isAuthenticated, login, logout, setIsAuthenticated}

    return (
        <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
    )
}

export const UseAuth = () => {
    return useContext(AuthContext)
}
