import {Navigate, useLocation} from "react-router-dom";
import {UseAuth} from "./Auth";

function RequireAuth({children}) {
    const auth = UseAuth();
    const location = useLocation();
    const query = new URLSearchParams(location.search + window.location.hash);

    if (query.has('order') && (query.has('email') || query.has('phone'))) {
        location.state = {
            order: encodeURI(query.get("order")),
            email: encodeURI(query.get("email")),
            phone: encodeURI(query.get("phone"))
        }
    }

    return auth.isAuthenticated === true || location.state !== null
        ? children
        : <Navigate to="/login" state={{from: location}} replace/>;
}

export default RequireAuth;
