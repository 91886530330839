import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { api } from '../../services/api'
import FullLoading from '../Utils/FullLoading'
import { sha256 } from 'js-sha256'

const Redirect = () => {
  const params = useParams()

  const id = params.id

  useEffect(() => {
    if (id !== null && id !== undefined) {
      api.get("system/url-shortener/" + id + "/").then(r => {
        if (r.status === 200) {
          const url = r.data?.long_url

          const parsed = new URL(url)
          const params = new URLSearchParams(decodeURIComponent(parsed.search))
          const phone = params.get("phone").replace(' ', '+')
          const phoneHash = sha256(phone);
          parsed.searchParams.set('phone', phoneHash)

          window.location.href =  parsed.toString();
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }, [])

  return (<><FullLoading/></>)
}

export default Redirect
